import React from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import NotFound from 'pages/NotFound';
import MainLayout from '../layouts/MainLayout';
import { DEBUG, languageList, PAYMENT_SYSTEM } from '../utils/constants';
import { Container, MenuItem, Select, ThemeProvider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import theme2 from 'theme/theme2';

const DynamicPage = React.lazy(() => import('pages/DynamicPage/DynamicPage'));
const EmailPage = React.lazy(() => import('pages/EmailPage'));
const AnalyzingPage = React.lazy(() => import('pages/AnalyzingPage'));
const PaymentPage = React.lazy(() => import('pages/PaymentPage/PaymentPage'));
const OfferPage = React.lazy(() => import('pages/OfferPage/OfferPage'));
const EmailConfirmedPage = React.lazy(() => import('pages/EmailСonfirmedPage'));
const ManageSubscriptionsPage = React.lazy(() =>
  import('pages/ManageSubscriptionsPage/ManageSubscriptionsPage')
);
const ManageSubscriptionsPageOld = React.lazy(() =>
  import('pages/ManageSubscriptionsPage/ManageSubscriptionsPageOld')
);
const PayWallPage = React.lazy(() => import('pages/PayWallPage/PayWallPage'));
const ThanksPage = React.lazy(() => import('pages/ThanksPage/ThanksPage'));
const WelcomeLetter = React.lazy(() =>
  import('pages/WelcomeLetter/WelcomeLetter')
);
const AllInOneApp = React.lazy(() => import('pages/AllInOneApp'));
const LoginPage = React.lazy(() => import('pages/LoginPage/LoginPage'));
const AccountPage = React.lazy(() => import('pages/AccountPage'));
const PauseSubscriptionPage = React.lazy(() =>
  import('pages/CancelSubscription/PauseSubscriptionPage')
);
const PauseSubscriptionConfirmPage = React.lazy(() =>
  import('pages/CancelSubscription/PauseSubscriptionConfirmPage')
);
const ImprovePage = React.lazy(() =>
  import('pages/CancelSubscription/ImprovePage')
);
const DontUsePage = React.lazy(() =>
  import('pages/CancelSubscription/DontUsePage')
);
const TryAgainPage = React.lazy(() =>
  import('pages/CancelSubscription/TryAgainPage')
);
const ThoughtPage = React.lazy(() =>
  import('pages/CancelSubscription/ThoughtsPage')
);
const CreatingOfferPage = React.lazy(() =>
  import('pages/CancelSubscription/CreatingOfferPage')
);
const UpdatingSubscriptionPage = React.lazy(() =>
  import('pages/CancelSubscription/UpdatingSubscriptionPage')
);
const PersonalDiscountPage = React.lazy(() =>
  import('pages/CancelSubscription/PersonalDiscountPage')
);
const SorryToYourGoPage = React.lazy(() =>
  import('pages/CancelSubscription/SorryToYourGoPage')
);
const NeedMoreTimePage = React.lazy(() =>
  import('pages/CancelSubscription/NeedMoreTimePage')
);
const WantForFreePage = React.lazy(() =>
  import('pages/CancelSubscription/WantForFreePage')
);
const AlternativeAppsPage = React.lazy(() =>
  import('pages/CancelSubscription/AlternativeAppsPage')
);
const DidntLikeContentPage = React.lazy(() =>
  import('pages/CancelSubscription/DidntLikeContentPage')
);

const PrivacyPolicy = React.lazy(() =>
  import('pages/ManageSubscriptionsPage/PrivacyPolicy')
);
const TermsOfService = React.lazy(() =>
  import('pages/ManageSubscriptionsPage/TermsOfService')
);

const AnimatedRoutes = () => {
  const location = useLocation();
  const { i18n } = useTranslation();

  return (
    <>
      {DEBUG && (
        <Select
          value={i18n.language}
          onChange={(e) => i18n.changeLanguage(e.target.value)}
          size="small"
          sx={{
            position: 'fixed',
            top: 8,
            left: 8,
            zIndex: 9999,
            backgroundColor: 'white',
          }}
        >
          {languageList.map((language) => (
            <MenuItem value={language} key={language}>
              {language}
            </MenuItem>
          ))}
        </Select>
      )}
      <SwitchTransition mode="out-in" className="item">
        <CSSTransition
          key={location.pathname}
          timeout={350}
          classNames="item"
          unmountOnExit
          mountOnEnter
        >
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<MainLayout />}>
              <Route path="/" element={<Navigate to="Page1" />} />
              <Route path=":pageName" element={<DynamicPage />} />
            </Route>
            <Route path="/all-in-one-app" element={<AllInOneApp />} />
            <Route path="/analyzing" element={<AnalyzingPage />} />
            <Route path="/paywall" element={<PayWallPage />} />

            <Route path="/email" element={<EmailPage />} />
            <Route path="/subscriptions" element={<PaymentPage />} />
            <Route path="/offer" element={<OfferPage />} />
            <Route
              path="/manageSubscriptionsOld"
              element={<ManageSubscriptionsPageOld />}
            />
            <Route
              path="/manageSubscriptions"
              element={
                <ThemeProvider theme={theme2}>
                  <Container maxWidth="xs">
                    <Outlet />
                  </Container>
                </ThemeProvider>
              }
            >
              <Route path="" element={<ManageSubscriptionsPage />} />
              <Route path="pause" element={<PauseSubscriptionPage />} />
              <Route
                path="pause-confirm"
                element={<PauseSubscriptionConfirmPage />}
              />
              <Route path="how-improve" element={<ImprovePage />} />
              <Route path="dont-use" element={<DontUsePage />} />
              <Route path="try-again" element={<TryAgainPage />} />
              <Route path="share-thoughts" element={<ThoughtPage />} />
              <Route path="creating-offer" element={<CreatingOfferPage />} />
              <Route
                path="updating-subscription"
                element={<UpdatingSubscriptionPage />}
              />
              <Route
                path="personal-discount"
                element={<PersonalDiscountPage />}
              />
              <Route path="sorry-to-your-go" element={<SorryToYourGoPage />} />
              <Route path="need-more-time" element={<NeedMoreTimePage />} />
              <Route path="want-for-free" element={<WantForFreePage />} />
              <Route
                path="alternative-apps"
                element={<AlternativeAppsPage />}
              />
              <Route
                path="didnt-like-content"
                element={<DidntLikeContentPage />}
              />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="terms-of-service" element={<TermsOfService />} />
            </Route>
            <Route path="/emailConfirmed" element={<EmailConfirmedPage />} />
            <Route path="/thanks" element={<ThanksPage />} />
            <Route path="/welcome" element={<WelcomeLetter />} />
            <Route path="/al:path" element={<></>} />
            <Route
              path="/login"
              element={
                <ThemeProvider theme={theme2}>
                  <LoginPage />
                </ThemeProvider>
              }
            />
            <Route
              path="/account"
              element={
                <ThemeProvider theme={theme2}>
                  <AccountPage />
                </ThemeProvider>
              }
            />

            <Route
              path="/stripe/cancel"
              element={
                <Navigate to={`/subscriptions?type=${PAYMENT_SYSTEM.STRIPE}`} />
              }
            />
            <Route
              path="/stripe/success"
              element={
                <Navigate to={`/thanks?type=${PAYMENT_SYSTEM.STRIPE}`} />
              }
            />

            <Route path="/not-found" element={<NotFound />} />
          </Routes>
        </CSSTransition>
      </SwitchTransition>
    </>
  );
};

export default AnimatedRoutes;
